import React from "react";
import map from "../../src/utils/images/karta.jpg";

const Footer = () => {
  return (
    <div id="contact-us" className="bg-sky-500 text-white font-bold py-4">
      <div className="text-3xl">Kontakta oss</div>
      <div className="flex flex-col md:flex-row justify-between w-full md:w-2/4 mx-auto py-2 px-2">
        <div className="text-left">
          <div className="flex">
            <p>Tel:</p>
            <p className="font-normal pl-2">072-934 19 55</p>
          </div>
          <div className="flex">
            <p>Epost:</p>
            <p className="font-normal pl-2">bilfixab@hotmail.com</p>
          </div>
          <div className="flex">
            <p>Öppettider:</p>
            <p className="font-normal pl-2">mån - fre: 09:30 - 17, lör: vid behov</p>
          </div>
        </div>
        <div className="text-left ">
          <div className="flex">
            <p>Adress:</p>
            <p className="font-normal pl-2">Vindkraftsvägen 15</p>
          </div>
          <div className="flex">
            <p>Postnummer:</p>
            <p className="font-normal pl-2">135 70</p>
          </div>
          <div className="flex">
            <p>Stad:</p>
            <p className="font-normal pl-2">Stockholm</p>
          </div>
        </div>
      </div>

      <div className="py-8">
        <img src={map} className="w-full md:w-2/4 mx-auto" alt="" />
      </div>
    </div>
  );
};

export default Footer;
