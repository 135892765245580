import logoWhite from "../src/utils/images/bil_vit.png";
import logoBlue from "../src/utils/images/bil_bla.png";
import logoText from "../src/utils/images/text_bla.png";
import hero from "../src/utils/images/muscular-car-service-worker-repairing-vehicle.jpg";
import service from "../src/utils/images/service-image.jpg";
import tireChange from "../src/utils/images/tire-change.jpg";
import "./App.css";
import Hero from "./components/hero";
import { useEffect, useState } from "react";
import Card from "./components/card";
import Footer from "./components/footer";
import AnchorLink from 'react-anchor-link-smooth-scroll'

function App() {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
    }
  }, []);

  return (
    <div className="App">
      <div
        className={`transition ease-in-out duration-300 ${
          small !== true ? "bg-transparent" : "bg-white shadow-lg"
        } px-4 flex items-center justify-between w-full fixed z-50`}
      >
        <img
          src={small !== true ? logoWhite : logoBlue}
          alt=""
          className="w-10 py-4 z-20"
        />
        {small && <img src={logoText} alt="" style={{ width: 100 }} />}
        <AnchorLink href="#contact-us" className="scroll-smooth">
          <p
            className={`transition ease-in-out duration-300 cursor-pointer ${
              small !== true ? "text-white" : "text-black"
            }`}
          >
            Kontakta oss
          </p>
        </AnchorLink>
      </div>
      <Hero />
      <div className="">
        <div className="pt-4 pb-4">
          <p className="text-4xl">Vi har tjänster så som</p>
        </div>
        <div className="w-9/12 mx-auto flex flex-col md:flex-row justify-center items-center">
          <Card type="Reparation" url={hero} />
          <Card type="Service" url={service} />
          <Card type="Däckskifte" url={tireChange} />
        </div>
      </div>
      <div className="py-6">
        <p className="text-2xl">
          Vi på Bil Fix AB har mer än 20 års erfarenhet när det gäller bilar.
        </p>
        <p className="text-2xl pt-2">
          Vi utför reparation, service och däckskifte på din bil.
        </p>
        <p className="text-2xl pt-2">
          Vi utför ingen AC service eller påfyllning.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default App;
