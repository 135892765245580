import React from "react";
import hero from "../../src/utils/images/muscular-car-service-worker-repairing-vehicle.jpg";
import logoText from '../utils/images/text_vit.png'

const style = {
  backgroundImage: `url(${hero})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  height: "60vh",
};

const isMobile = window.innerWidth < 500

const Hero = () => {
  return (
    <div className="" style={style}>
      <div className="bg-black/50 w-full h-full">
        <div className="w-full h-full flex flex-col justify-center items-center">
            <img src={logoText} alt="logo text" className="pb-4" style={{width: isMobile ? "60%" : "25%"}} />
            <h3 className="text-white text-3xl">Vi fixar din bil</h3>
        </div>
      </div>
    </div>
  );
};

export default Hero;
