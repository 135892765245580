import React from "react";

const Card = (props) => {
  return (
    <div className="w-60 flex flex-col rounded-md overflow-hidden border-2 mx-4 my-4">
      <div>
        <img src={props.url} alt={props.type} />
        <p className="text-2xl py-2">{props.type}</p>
      </div>
    </div>
  );
};

export default Card;
